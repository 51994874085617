import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios'

export default function Withdrawal() {
    const {
        account,
        toastSuccess, dbbalance,
        tokenrate,
        toastError, dbuser,
        getuserDB,
        connectMetamask,
    } = useContext(ProjectContext)
    const [amount, setamount] = useState(0);
    const [rcv_amount, setrcv_amount] = useState(0);
    const [fees, setfees] = useState(0);
    const [feesgaming, setfeesgaming] = useState(0);
    const [total_tokens, settotal_tokens] = useState(0);
    const [trdone, settrdone] = useState(false);

    const withdraw = async () => {
        if (!account) {
            connectMetamask();
            toastError("Connect first");
            settrdone(false);
            return;
        }
        if (!amount || amount < 0) {
            toastError("Enter amount");
            settrdone(false);
            return;
        }
        if (dbuser.restake) {
            toastError("Restake account");
            settrdone(false);
            return;
        }
        if (amount < 20) {
            toastError("Min $20 required");
            settrdone(false);
            return;
        }
        if (Number(amount) > dbbalance) {
            toastError("Insufficient Balance");
            settrdone(false);
            return;
        }
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "insert",
                key: process.env.REACT_APP_KEY,
                address: account,
                amount: amount,
                token: total_tokens,
                fee: fees,//admin
                fee_gaming: feesgaming,//gaming wallet
                t_rcv: rcv_amount,
                rate: tokenrate,
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    toastError(res.data.error);
                    return "";
                }
                toastSuccess(res.data.data);
            });
        getuserDB();
        // getpData()
        // getData()
        settrdone(false);
    }
    useEffect(() => {
        var tmt = amount / tokenrate;
        var tmtfee = (tmt * 5) / 100;
        var gmngfee = (tmt * 10) / 100;
        setfeesgaming(gmngfee);
        settotal_tokens(tmt);
        setfees(tmtfee);
        setrcv_amount(tmt - tmtfee - gmngfee);
    }, [amount, tokenrate]);



    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader/> */}
                <br /><br />
                <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                    <div className="container-fluid p-0">
                        <div className="contact-wrap-1">
                            <div className="row justify-content-around">
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title style2"># Hire Experience Gamer</span>
                                        <h2 className="sec-title text-white">Withdrawal &amp; Enjoy Reward </h2>
                                    </div>
                                    <div className="row">
                                        <span className='text-end'>Balance: ${dbbalance}</span>
                                        <div className="form-group style-border2 col-md-12">
                                            <input type="text" className="form-control" name="text" placeholder="Amount" value={amount}
                                                onChange={(e) =>
                                                    setamount(e.target.value)
                                                } /> <i className="fal fa-usd" />
                                        </div>
                                        {/* <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Rate</span> <span>${tokenrate}</span></div> */}
                                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Admin Fees</span> <span>{fees}{" "}USDT (5%)</span></div>
                                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Gaming Wallet</span> <span>{feesgaming}{" "}
                                            USDT (10%)</span></div>
                                        <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Received Amount</span> <span>{rcv_amount}{" "}
                                            USDT</span></div>
                                        <div className="form-btn col-12 my-5">
                                            <button className="th-btn" onClick={() => withdraw()}>Submit <i className="fa-solid fa-arrow-right ms-2" /></button>
                                        </div>
                                    </div>
                                    <p className="form-messages mb-0 mt-3" />
                                </div>
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s"><span className="sub-title style2"># Common Questions About Our
                                        Company</span>
                                        <h2 className="sec-title text-white">Frequently Asked Questions About Our Esports &amp; Gaming Site
                                        </h2>
                                    </div>
                                    <div className="accordion custom-anim-left wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s" id="faqAccordion">
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-1"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">What rewards do YBLWin pay to users?</button></div>
                                            <div id="collapse-1" className="accordion-collapse collapse show" aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">YBLWin distributes a variety of rewards to users including YBL token, crypto bonus, royalties and more</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-2"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">Which games users can play on YBLWin?
                                            </button></div>
                                            <div id="collapse-2" className="accordion-collapse collapse" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Users can play a wide range of games on YBLWin including casino, fantasy cricket, chess, carrom, pool and other arcade games. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-card">
                                            <div className="accordion-header" id="collapse-item-3"><button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">What additional rewards users can claim?</button></div>
                                            <div id="collapse-3" className="accordion-collapse collapse" aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                                <div className="accordion-body">
                                                    <p className="faq-text">Users can earn referral rewards, team royalty, pool income and more.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    )
}
