import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';

export default function Team() {
    const { showmenu, setshowmenu, account, Ranks, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [level, setlevel] = useState(1);
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [selectedP, setselectedP] = useState(0);
    const [selectedSrank, setselectedSrank] = useState(0);
    const [selectedPvalue, setselectedPvalue] = useState(null);
    const [selectedStatus, setselectedStatus] = useState(0);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "level",
                // address: "0xfded9e02beccedbddf19a1cf3a86769fca363427",
                address: account,
                level: level,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize,

                select_item: selectedP,
                select_value: selectedPvalue,

                fromdate: fromdate,
                todate: todate,
                srank: selectedSrank,
                sstatus: selectedStatus
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    // useEffect(() => {
    //     getData()
    // }, [level])

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <div className="container">
                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <h2 className="sec-title">My All Team </h2>
                        </div>
                        <div className="table-responsive">
                            <div className='row d-flex align-items-center justify-content-center'>
                                <div className="col-lg-2">
                                    <div className="form-group">
                                        <label htmlFor="" className='text-start text-white'>Level</label>
                                        <input className="form-control" type="text" placeholder="Enter level" value={level}
                                            onChange={(e) => setlevel(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <label htmlFor="" className='text-start text-white'>From</label>
                                    <div className="form-group">
                                        <input className="form-control" type="date" placeholder="Enter level" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <label htmlFor="" className='text-start text-white'>To</label>
                                    <div className="form-group">
                                        <input className="form-control" type="date" placeholder="Enter level" value={todate} onChange={(e) => settodate(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <label htmlFor="" className='text-start text-white'>Select</label>
                                    <div className="form-group style-border2">
                                        <select className="form-select" aria-label="Default select example" onChange={(e) => setselectedP(Number(e.target.value))}>
                                            <option selected="selected" value={0}>---Select--</option>
                                            <option value={1}>Username</option>
                                            <option value={2}>Display Name</option>
                                        </select>
                                        <i className="fal fa-angle-down" />
                                    </div>
                                </div>
                                {selectedP !== 0 ?
                                    <div className="col-lg-2">
                                        <div className="form-group">
                                            <label htmlFor="" className='text-start text-white'>Enter Value</label>
                                            <input className="form-control" type="text" placeholder="Enter value" value={selectedPvalue}
                                                onChange={(e) => setselectedPvalue(e.target.value)} />
                                        </div>
                                    </div> : ''}
                                <div className="col-lg-2">
                                    <label htmlFor="" className='text-start text-white'>Rank</label>
                                    <div className="form-group style-border2">
                                        <select className="form-select" aria-label="Default select example" onChange={(e) => setselectedSrank(Number(e.target.value))}>
                                            <option selected="selected" value={0}>---Select--</option>
                                            {Ranks.map((e) => {
                                                return <option value={e.rank_number}>{e.rank}</option>
                                            })}
                                        </select>
                                        <i className="fal fa-angle-down" />
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <label htmlFor="" className='text-start text-white'>Status</label>
                                    <div className="form-group style-border2">
                                        <select className="form-select" aria-label="Default select example" onChange={(e) => setselectedStatus(Number(e.target.value))}>
                                            <option selected="selected" value={0}>---All--</option>
                                            <option value={1}>Active</option>
                                            <option value={2}>Not Active</option>
                                        </select>
                                        <i className="fal fa-angle-down" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button>
                                </div>
                            </div>
                            <br />
                            {/* <div className='row'>
                                <div className="col-lg-7">
                                    <div className="form-group d-flex">
                                        <input className="form-control" type="number" placeholder="Enter level" value={level}
                                            onChange={(e) => setlevel(e.target.value)} />
                                        <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button></div>
                                </div>
                            </div> */}
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Joining Date</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Display Name</th>
                                        <th scope="col">Direct Team</th>
                                        <th scope="col">Direct Business</th>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Referral</th>
                                        <th scope="col">Package</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        <td className="text-center" colSpan={10}>
                                            {isLoading ? "Data is loading" : ""}
                                        </td>
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={10}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {ele.id}
                                                </td>
                                                <td>
                                                    {ele.createdAt}
                                                </td>
                                                <td>
                                                    <span onClick={() =>
                                                        // copyaddress(ele.address)
                                                        copyaddress(ele.uname)
                                                    }>
                                                        {/* {formatAddress(ele.address)}  <i className="fa fa-copy"></i> */}
                                                        {/* {formatAddress(ele.uname)}  <i className="fa fa-copy"></i> */}
                                                        {ele.uname}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span onClick={() =>
                                                        // copyaddress(ele.ref_address)
                                                        copyaddress(ele.dname)
                                                    }>
                                                        {/* {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i> */}
                                                        {/* {ele.ref_uname} */}
                                                        {ele.dname}
                                                    </span>
                                                </td>
                                                <td>
                                                    {ele.ref_team}
                                                </td>
                                                <td>
                                                    $ {ele.direct_team_business}
                                                </td>
                                                <td>
                                                    {ele.rank}
                                                </td>
                                                <td>
                                                    {ele.referral_uname}
                                                </td>
                                                <td>
                                                    $ {ele.t_staking}
                                                </td>
                                                <td>
                                                    {ele.status ? (
                                                        <div className="text-success">
                                                            Active
                                                        </div>
                                                    ) : (
                                                        <div className="text-danger">
                                                            Not Active
                                                        </div>
                                                    )}
                                                </td>
                                                {/* <td>
                                                    $ {ele.team_business} ({ele.team})
                                                </td> */}


                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pages={pages}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
