import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';

export default function TeamDirectLegs() {
    const { account, copyaddress, dbuser } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [leg, setleg] = useState('N');

    const getData = async () => {
        if (!account) {
            return;
        }
        if (leg !== 'A' && leg !== 'B' && leg !== 'O') {
            return
        }
        setisLoading(true);
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "stronguser",
                address: dbuser ? leg === 'A' ? dbuser.alegaddress : leg === 'B' ? dbuser.blegaddress : leg === 'O' ? dbuser.olegaddress : [] : [],
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
        console.log(leg);
        
    }, [leg,pages, pageSize, currentPage]);


    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <div className="container">
                        <div className="title-area text-center d-flex justify-content-between" >
                            <div className="form-group ">
                                <select className="form-select" aria-label="legs" onChange={(e) => setleg(e.target.value)}>
                                    <option selected="selected" value='N'>---Select Team---</option>
                                    <option value='A'>Team A</option>
                                    <option value='B'>Team B</option>
                                    <option value='O'>Other Team</option>
                                </select>

                            </div>
                            <h2 className="sec-title"> Team </h2>
                        </div>
                        <div className="table-responsive">
                            {/* <div className='row'>
                                <div className="col-lg-7">
                                    <div className="form-group d-flex">
                                        <input className="form-control" type="number" placeholder="Enter level" value={level}
                                            onChange={(e) => setlevel(e.target.value)} />
                                        <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button></div>
                                </div>
                            </div> */}
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Team user</th>
                                        <th scope="col">Joining Date</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Display Name</th>
                                        {/* <th scope="col">Referral</th> */}
                                        <th scope="col">Rank</th>
                                        <th scope="col">Package</th>
                                        <th scope="col">Team Business</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center ">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={8}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {ele.id}
                                                </td>
                                                <td>
                                                    {ele.isleg ?"Team User":"Team sub user"}
                                                </td>
                                                <td>
                                                    {ele.createdAt}
                                                </td>
                                                <td>
                                                    <span onClick={() =>
                                                        // copyaddress(ele.address)
                                                        copyaddress(ele.uname)
                                                    }>
                                                        {/* {formatAddress(ele.address)}  <i className="fa fa-copy"></i> */}
                                                        {/* {formatAddress(ele.uname)}  <i className="fa fa-copy"></i> */}
                                                        {ele.uname}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span onClick={() =>
                                                        // copyaddress(ele.ref_address)
                                                        copyaddress(ele.ref_uname)
                                                    }>
                                                        {/* {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i> */}
                                                        {/* {formatAddress(ele.ref_uname)}  <i className="fa fa-copy"></i> */}
                                                        {/* {ele.ref_uname} */}
                                                        {ele.dname}
                                                    </span>
                                                </td>
                                                <td>
                                                    {ele.rank}
                                                </td>
                                                <td>
                                                    $ {ele.t_staking}
                                                </td>
                                                <td>
                                                    $ {ele.team_business} ({ele.team})
                                                </td>

                                                <td>
                                                    {ele.status ? (
                                                        <div className="text-success">
                                                            Active
                                                        </div>
                                                    ) : (
                                                        <div className="text-danger">
                                                            Not Active
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                            pages={pages}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
