import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';
import { Modal } from 'react-bootstrap';

export default function Achievements() {
    const { showmenu, setshowmenu, account, copyaddress,checkNegative, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [pdata, setpdata] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(0);
    const [show, setShow] = useState(false);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "rank",
                submethod: "getbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
                const sum = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.income;
                }, 0);
                setsumIncome(sum)

            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <div className="container">
                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <h2 className="sec-title">Achievements </h2>
                        </div>
                        <div className="table-responsive">
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Self Id </th>
                                        <th scope="col">Direct Team</th>
                                        <th scope="col">Direct Business</th>
                                        <th scope="col">Total Team </th>
                                        <th scope="col">Team Business</th>
                                        <th scope="col">Reward</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={9}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {ele.id}
                                                </td>
                                                <td >
                                                    {ele.rank}
                                                </td>
                                                <td >
                                                    $ {ele.self_staking}
                                                </td>
                                                <td >
                                                    {ele.direct}
                                                </td>
                                                <td >
                                                    $ {ele.direct_business}
                                                </td>
                                                <td >
                                                    {ele.team}
                                                </td>
                                                <td >
                                                    $ {ele.team_business}
                                                </td>
                                                <td >
                                                    $ {ele.rank_reward}
                                                </td>
                                                <td >
                                                    {ele.status ? <span className='text-success'>Achieved <i className="fa fa-check-circle" aria-hidden="true" role="button" ></i></span> : <span className='text-warning'>Not Achieved <i className="fa fa-info-circle" aria-hidden="true" role="button" onClick={() => { setShow(true); setpdata(ele) }}></i>
                                                    </span>}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Rank</th>
                                        <th scope="col">Self Id </th>
                                        <th scope="col">Direct Team</th>
                                        <th scope="col">Direct Business</th>
                                        <th scope="col">Total Team </th>
                                        <th scope="col">Team Business</th>
                                        <th scope="col">Reward</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)} size="lg">
                <div className='d-flex justify-content-center '>
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                            <h5 className="modal-title" id="exampleModalLabel">Self Data</h5>
                            <button type="button" className="close" onClick={() => setShow(false)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <Modal.Body>
                            {/* <div className="text-dark  d-flex w-100" style={{ overflowX: 'auto' }} >
                                <div className=" style-border2  w-100 p-2"><span>Self ID</span><br /> <span className="text-break text-end">$ {pdata !== null ? pdata.user_self_staking : 0}</span></div>
                                <div className=" style-border2  w-100 p-2"><span>Direct Team</span> <br /><span className="text-break text-end">{pdata !== null ? pdata.user_direct_team : 0}</span></div>
                                <div className=" style-border2  w-100 p-2"><span>Total Team</span><br /> <span className="text-break text-end">{pdata !== null ? pdata.user_t_team : 0}</span></div>
                                <div className=" style-border2  w-100 p-2"><span>Direct Business</span> <br /><span className="text-break text-end">$ {pdata !== null ? pdata.user_direct_team_business : 0}</span></div>
                            </div> */}
                            {/* <h5><span className='text-dark fs-20'>Your data</span></h5> */}
                            <div className="text-dark d-flex justify-content-between">
                                <div className="px-1">
                                    <div>Self ID</div>
                                    <span className=" text-center">$ {pdata !== null ? pdata.user_self_staking : 0}</span>
                                </div>
                                <div className="px-1">
                                    <div>Direct Team</div>
                                    <span className=" text-center">{pdata !== null ? pdata.user_direct_team : 0}</span>
                                </div>
                                <div className="px-1">
                                    <div>Total Team</div>
                                    <span className=" text-center">{pdata !== null ? pdata.user_t_team : 0}</span>
                                </div>
                                <div className="px-1">
                                    <div>Direct Business</div>
                                    <span className=" text-center">{pdata !== null ? pdata.user_direct_team_business : 0}</span>
                                </div>
                            </div>
                            <br />
                            <div className="text-dark ">
                                <div className="d-flex justify-content-between">
                                    <div> Req. Self ID </div>
                                    <span className=" text-center">$ {pdata !== null ? checkNegative(pdata.self_staking - pdata.user_self_staking) : 0}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div> Req. Direct Team </div>
                                    <span className=" text-center">{pdata !== null ? checkNegative(pdata.direct - pdata.user_direct_team) : 0}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div> Req. Total Team </div>
                                    <span className=" text-center"> {pdata !== null ? checkNegative(pdata.team - pdata.user_t_team) : 0}</span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div> Req. Direct Business </div>
                                    <span className=" text-center"> {pdata !== null ? checkNegative(pdata.direct_business - pdata.user_direct_team_business) : 0}</span>
                                </div>
                            </div>
                            <br />
                            <div className="text-dark d-flex justify-content-between">
                                <div className="px-1">
                                    <div>Leg A</div>
                                    <span className=" text-center">$ {pdata !== null ? pdata.r_aleg : 0}</span>
                                </div>
                                <div className="px-1">
                                    <div>Leg B</div>
                                    <span className=" text-center">$ {pdata !== null ? pdata.r_bleg : 0}</span>
                                </div>
                                <div className="px-1">
                                    <div>Leg C</div>
                                    <span className=" text-center">$ {pdata !== null ? pdata.r_oleg : 0}</span>
                                </div>
                            </div>
                            
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        </>
    )
}