import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ProjectContext } from '../../ProjectContext';
import Popover from "react-bootstrap/Popover";
import { OverlayTrigger } from 'react-bootstrap';
export default function DashboardSidebar() {
    const [show, setShow] = useState(false);
    const { disconnectNow, dbbalance } = useContext(ProjectContext);
    var navigate = useNavigate();


    const logout = () => {
        disconnectNow();
        navigate('/')
    }
    const currentPath = window.location.pathname;

    const [subMenu, setSubMenu] = useState(false);
    const [subMenuTeam, setSubMenuTeam] = useState(false);
    const [subMenuRevenue, setSubMenuRevenue] = useState(false);
    const [subMenuWithdraw, setSubMenuWithdraw] = useState(false);
    const [subMenuGaming, setSubMenuGaming] = useState(false);

    const popoverTop = (
        <Popover
            id="popover-positioned-top"
            className="popover"
            title="Popover top"
        >
            <strong>
                <a href="https://help.yblwin.ai/" target="_blank" rel="noreferrer" style={{ fontSize: "12px" }}>
                    Need <br /> Help ?
                </a>
            </strong>
        </Popover>
    );
    return (
        <>
            <header className="th-header header-default">
                {/* <div className="sticky-wrapper"> */}
                <div className="menu-area">
                    <div className="container">
                        <div className="row align-items-center justify-content-between py-3">
                            <div className="header-button d-flex justify-content-between d-lg-none">
                                <div className="header-logo-wrap">
                                    <div className="header-logo"><Link to="/"><span data-mask-src="./../assets/img/logo.png" className="logo-mask" /> <img src="./../assets/img/logo.png" alt="YBLWin" /></Link></div>
                                </div>
                                <button type="button" className="th-menu-toggle" onClick={() => logout()}><span className="btn-border" /><i className="far fa-sign-out" />
                                </button>
                                <button type="button" className="th-menu-toggle" onClick={() => setShow(!show)}><span className="btn-border" /><i className="far fa-bars" /></button>
                            </div>
                            {/* <div className="col-auto d-none d-xl-block">
                                <div className="header-button">
                                    <div className="d-lg-block d-none">
                                        <Link className="th-btn">
                                            <i className="fa-brands fa-twitch me-1" /> $0
                                        </Link>
                                        <button className="animated-border-button" onClick={() => logout()}>
                                            <i className="fa-brands fa-twitch me-1" /> Logout
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-auto">
                                <nav className="main-menu d-none d-lg-inline-block">

                                </nav>
                            </div>
                            <div className="col-auto d-none d-xl-block">
                                <div className="header-button">
                                    <div className="d-lg-block d-none">
                                        <Link className="th-btn">
                                            <i className="fa-brands fa-twitch me-1" />  ${dbbalance}
                                        </Link>
                                        <button className="animated-border-button" onClick={() => logout()}>
                                            <i className="fa-brands fa-twitch me-1" /> Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </header>
            <div className="sidebar open d-lg-block d-none">
                <div className="logo-details">
                    {/* <i className="bx bxl-codepen icon" /> */}
                    <div className="logo_name text-center"> <img src="./../assets/img/logo.png" width="200" className='my-5' alt="Bame" /></div>
                    <i className="bx bx-menu" id="btn" />
                </div>
                <ul className="nav-list">
                    {/* <li>
                        <i className="bx bx-search" />
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li> */}
                    <li className='mt-5'>
                        <Link to="/dashboard" className={`${currentPath === "/dashboard" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>

                            <span className="links_name">Dashboard</span>
                        </Link>
                        <span className="tooltip">Dashboard</span>
                    </li>
                    <li>
                        <Link to="#" className={`${currentPath === "/stake" || currentPath === "/stakings" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenu(!subMenu)}>
                            <span className="links_name">Invest</span>
                            {subMenu
                                ?
                                <i class="fa-solid fa-circle-minus"></i>
                                :
                                <i class="fa-solid fa-circle-plus"></i>
                            }
                        </Link>
                        <span className="tooltip">Invest</span>
                        {subMenu
                            ?
                            <ul className="sub-menu">
                                <li><Link to="/stake"><span className="text">Invest</span></Link></li>
                                <li><Link to="/stakings"><span className="text">History</span></Link></li>
                            </ul>
                            : ""}
                    </li>
                    <li>
                        <Link to='#' className={`${currentPath === "/team" || currentPath === "/team-legs" || currentPath === "/team-direct" || currentPath === "/tree" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuTeam(!subMenuTeam)}>
                            <span className="links_name">Team</span>
                            {subMenuTeam
                                ?
                                <i class="fa-solid fa-circle-minus"></i>
                                :
                                <i class="fa-solid fa-circle-plus"></i>
                            }
                        </Link>
                        <span className="tooltip">Team</span>
                        {subMenuTeam
                            ?
                            <ul className="sub-menu">
                                <li><Link to="/team-direct"><span className="text">My Direct</span></Link></li>
                                <li><Link to="/team"><span className="text">My All Team </span></Link></li>
                                <li><Link to="/team-legs"><span className="text">Team Details </span></Link></li>
                                <li><Link to="/tree"><span className="text">Tree View</span></Link></li>
                            </ul>
                            : ""}
                    </li>
                    <li>
                        <Link to='#' className={`${currentPath === "/staking-income" || currentPath === "/roiofroi-income" || currentPath === "/community-income" || currentPath === "/royalty-income" || currentPath === "/achievements" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuRevenue(!subMenuRevenue)}>
                            <span className="links_name">Revenue Share</span>
                            {subMenuRevenue
                                ?
                                <i class="fa-solid fa-circle-minus"></i>
                                :
                                <i class="fa-solid fa-circle-plus"></i>
                            }
                        </Link>
                        <span className="tooltip">Revenue share</span>
                        {subMenuRevenue
                            ?
                            <ul className="sub-menu">
                                <li><Link to="/staking-income"><span className="text">Weekly Revenue</span></Link></li>
                                <li><Link to="/roiofroi-income"><span className="text">Team Revenue Share </span></Link></li>
                                <li><Link to="/community-income"><span className="text">Community Bonus</span></Link></li>
                                <li><Link to="/royalty-income"><span className="text">Leadership Bonus</span></Link></li>
                                <li><Link to="/achievements"><span className="text">Achievements</span></Link></li>
                                <li><Link to="/comming-soon"><span className="text">Gaming Bonus</span></Link></li>
                            </ul>
                            : ""}
                    </li>
                    <li>
                        <Link to='#' className={`${currentPath === "/withdrawal" || currentPath === "/withdrawal-history" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuWithdraw(!subMenuWithdraw)}>
                            <span className="links_name">Withdrawal</span>
                            {subMenuWithdraw
                                ?
                                <i class="fa-solid fa-circle-minus"></i>
                                :
                                <i class="fa-solid fa-circle-plus"></i>
                            }
                        </Link>
                        <span className="tooltip">Withdrawal</span>
                        {subMenuWithdraw
                            ?
                            <ul className="sub-menu">
                                <li><Link to="/withdrawal"><span className="text">Withdrawal</span></Link></li>
                                <li><Link to="/withdrawal-history"><span className="text">History</span></Link></li>
                            </ul>
                            : ""}
                    </li>

                    {/* <li>
                        <Link to='/withdrawal' className={`${currentPath === "/withdrawal" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>
                            <span className="links_name">Withdrawal</span>
                        </Link>
                        <span className="tooltip">Withdrawal</span>
                    </li> */}
                    <li>
                        <Link to='#' className={`${currentPath === "/gaming-history" || currentPath === "/gaming-wallet" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuGaming(!subMenuGaming)}>
                            <span className="links_name">Gaming Wallet</span>
                            {subMenuGaming
                                ?
                                <i class="fa-solid fa-circle-minus"></i>
                                :
                                <i class="fa-solid fa-circle-plus"></i>
                            }
                        </Link>
                        <span className="tooltip">Gaming Wallet</span>
                        {subMenuGaming
                            ?
                            <ul className="sub-menu">
                                <li><Link to="/gaming-history"><span className="text">History Statement</span></Link></li>
                                <li><Link to="/gaming-wallet"><span className="text">Transfer </span></Link></li>
                            </ul>
                            : ""}
                    </li>
                    <li>
                        <Link to='https://help.yblwin.ai/' target='_blank' className="d-flex justify-content-start">
                            <span className="links_name">Help Desk</span>
                        </Link>
                        <span className="tooltip">Help Desk</span>
                    </li>

                    <li>
                        <Link to='/profile' className={`${currentPath === "/profile" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>
                            <span className="links_name">Profile</span>
                        </Link>
                        <span className="tooltip">Profile</span>
                    </li>
                    <li>
                        <a href="./../assets/presentation.pdf" target='_blank' className={`${currentPath === "/table" ? "d-flex justify-content-start" : "d-flex justify-content-start"}`}>
                            <span className="links_name">Presentation</span>
                        </a>
                        <span className="tooltip">Presentation</span>
                    </li>
                    <li>
                        <Link to="/support" className={`${currentPath === "/table" ? "d-flex justify-content-start" : "d-flex justify-content-start"}`}>
                            <span className="links_name">Support</span>
                        </Link>
                        <span className="tooltip">Support</span>
                    </li>
                    <li className="profile">
                        <div className="profile-details">
                            <i className="bx bx-export" />
                            <div className="name_job text-center" onClick={logout}>
                                <div className="name">Logout</div>
                            </div>
                        </div>
                        <i className="bx bx-log-out" id="log_out" />
                    </li>
                </ul>
            </div>
            {show
                ?
                <>
                    <div className="sidebar open">
                        <div className="logo-details">
                            {/* <i className="bx bxl-codepen icon" /> */}
                            <div className="logo_name text-center"> <img src="assets/img/logo.png" width="200" className='my-5' alt="Bame" /></div>
                            <i className="bx bx-menu" id="btn" />
                        </div>
                        <ul className="nav-list">
                            {/* <li>
                        <i className="bx bx-search" />
                        <input type="text" placeholder="Search..." />
                        <span className="tooltip">Search</span>
                    </li> */}
                            <li className='mt-5'>
                                <Link to="/dashboard" className={`${currentPath === "/dashboard" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>

                                    <span className="links_name">Dashboard</span>
                                </Link>
                                <span className="tooltip">Dashboard</span>
                            </li>
                            <li>
                                <Link to="#" className={`${currentPath === "/stake" || currentPath === "/stakings" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenu(!subMenu)}>
                                    <span className="links_name">Invest</span>
                                    {subMenu
                                        ?
                                        <i class="fa-solid fa-circle-minus"></i>
                                        :
                                        <i class="fa-solid fa-circle-plus"></i>
                                    }
                                </Link>
                                <span className="tooltip">Invest</span>
                                {subMenu
                                    ?
                                    <ul className="sub-menu">
                                        <li><Link to="/stake"><span className="text">Invest</span></Link></li>
                                        <li><Link to="/stakings"><span className="text">History</span></Link></li>
                                    </ul>
                                    : ""}
                            </li>
                            <li>
                                <Link to='#' className={`${currentPath === "/team" || currentPath === "/team-legs" || currentPath === "/team-direct" || currentPath === "/tree" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuTeam(!subMenuTeam)}>
                                    <span className="links_name">Team</span>
                                    {subMenuTeam
                                        ?
                                        <i class="fa-solid fa-circle-minus"></i>
                                        :
                                        <i class="fa-solid fa-circle-plus"></i>
                                    }
                                </Link>
                                <span className="tooltip">Team</span>
                                {subMenuTeam
                                    ?
                                    <ul className="sub-menu">
                                        <li><Link to="/team-direct"><span className="text">My Direct</span></Link></li>
                                        <li><Link to="/team"><span className="text">My All Team </span></Link></li>
                                        <li><Link to="/team-legs"><span className="text">Team Details </span></Link></li>
                                        <li><Link to="/tree"><span className="text">Tree View</span></Link></li>
                                    </ul>
                                    : ""}
                            </li>
                            <li>
                                <Link to='#' className={`${currentPath === "/staking-income" || currentPath === "/roiofroi-income" || currentPath === "/community-income" || currentPath === "/royalty-income" || currentPath === "/achievements" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuRevenue(!subMenuRevenue)}>
                                    <span className="links_name">Revenue Share</span>
                                    {subMenuRevenue
                                        ?
                                        <i class="fa-solid fa-circle-minus"></i>
                                        :
                                        <i class="fa-solid fa-circle-plus"></i>
                                    }
                                </Link>
                                <span className="tooltip">Revenue share</span>
                                {subMenuRevenue
                                    ?
                                    <ul className="sub-menu">
                                        <li><Link to="/staking-income"><span className="text">Weekly Revenue</span></Link></li>
                                        <li><Link to="/roiofroi-income"><span className="text">Team Revenue Share </span></Link></li>
                                        <li><Link to="/community-income"><span className="text">Community Bonus</span></Link></li>
                                        <li><Link to="/royalty-income"><span className="text">Leadership Bonus</span></Link></li>
                                        <li><Link to="/achievements"><span className="text">Achievements</span></Link></li>
                                        <li><Link to="/comming-soon"><span className="text">Gaming Bonus</span></Link></li>
                                    </ul>
                                    : ""}
                            </li>
                            <li>
                                <Link to='#' className={`${currentPath === "/withdrawal" || currentPath === "/withdrawal-history" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuWithdraw(!subMenuWithdraw)}>
                                    <span className="links_name">Withdrawal</span>
                                    {subMenuWithdraw
                                        ?
                                        <i class="fa-solid fa-circle-minus"></i>
                                        :
                                        <i class="fa-solid fa-circle-plus"></i>
                                    }
                                </Link>
                                <span className="tooltip">Withdrawal</span>
                                {subMenuWithdraw
                                    ?
                                    <ul className="sub-menu">
                                        <li><Link to="/withdrawal"><span className="text">Withdrawal</span></Link></li>
                                        <li><Link to="/withdrawal-history"><span className="text">History</span></Link></li>
                                    </ul>
                                    : ""}
                            </li>

                            {/* <li>
                        <Link to='/withdrawal' className={`${currentPath === "/withdrawal" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>
                            <span className="links_name">Withdrawal</span>
                        </Link>
                        <span className="tooltip">Withdrawal</span>
                    </li> */}
                            <li>
                                <Link to='#' className={`${currentPath === "/gaming-history" || currentPath === "/gaming-wallet" ? "d-flex justify-content-between active py-0" : "d-flex justify-content-between py-0"}`} onClick={() => setSubMenuGaming(!subMenuGaming)}>
                                    <span className="links_name">Gaming Wallet</span>
                                    {subMenuGaming
                                        ?
                                        <i class="fa-solid fa-circle-minus"></i>
                                        :
                                        <i class="fa-solid fa-circle-plus"></i>
                                    }
                                </Link>
                                <span className="tooltip">Gaming Wallet</span>
                                {subMenuGaming
                                    ?
                                    <ul className="sub-menu">
                                        <li><Link to="/gaming-history"><span className="text">History Statement</span></Link></li>
                                        <li><Link to="/gaming-wallet"><span className="text">Transfer </span></Link></li>
                                    </ul>
                                    : ""}
                            </li>
                            <li>
                                <Link to='https://help.yblwin.ai/' target='_blank' className="d-flex justify-content-start">
                                    <span className="links_name">Help Desk</span>
                                </Link>
                                <span className="tooltip">Help Desk</span>
                            </li>

                            <li>
                                <Link to='/profile' className={`${currentPath === "/profile" ? "d-flex justify-content-start active" : "d-flex justify-content-start"}`}>
                                    <span className="links_name">Profile</span>
                                </Link>
                                <span className="tooltip">Profile</span>
                            </li>
                            <li>
                                <a href="./../assets/presentation.pdf" target='_blank' className={`${currentPath === "/table" ? "d-flex justify-content-start" : "d-flex justify-content-start"}`}>
                                    <span className="links_name">Presentation</span>
                                </a>
                                <span className="tooltip">Presentation</span>
                            </li>
                            <li className="profile">
                                <div className="profile-details">
                                    <i className="bx bx-export" />
                                    <div className="name_job text-center" onClick={logout}>
                                        <div className="name">Logout</div>
                                    </div>
                                </div>
                                <i className="bx bx-log-out" id="log_out" />
                            </li>
                        </ul>
                    </div>

                </>
                :
                ""
            }
            <OverlayTrigger trigger="click" placement="top" overlay={popoverTop}>
                {/* <img width={90} height={80} src="assets/assets/img/chat-boat.png" alt="" className='sticky' style={{ cursor: 'pointer', float: "right", position: "absolute", bottom: "0", zIndex: "999", paddingRight: "10px", right: "0" }} /> */}
                <img
                    width={70}
                    height={70}
                    src="./../assets/img/chat_bot.png"
                    alt=""
                    className="sticky"
                />
            </OverlayTrigger>
        </>
    )
}
