import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';

export default function ROIofROIincome() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress, dbuser } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(null);
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [selectedPvalue, setselectedPvalue] = useState(null);
    const [level, setlevel] = useState(0);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "roiofroi",
                submethod: "getbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                fromdate: fromdate,
                todate: todate,
                uname: selectedPvalue,
                level: level,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    setdata([]);
                    setsumIncome(null)
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
                const sum = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.income;
                }, 0);
                const sumstaking = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.staking;
                }, 0);
                const sums_inc = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.s_inc;
                }, 0);
                setsumIncome({
                    sum: sum,
                    staking: sumstaking,
                    s_inc: sums_inc
                })

            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                        <div className="tournament-sec-bg-shape" />
                        <div className="container">
                            <div className="row gy-4 filter-active justify-content-center">
                                <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                                    <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title">Team Revenue Share  </h2>
                                    </div>
                                    <div className="row gy-30 filter-active justify-content-center">
                                        <div className="col-lg-3 col-md-6 my-5">
                                            <div className="game-card style2">
                                                <div className="game-card-img">
                                                    <div className="game-logo"><img src="assets/img/total_business_month.svg" alt="YBL" /></div>
                                                </div>
                                                <div className="game-card-details">
                                                    <div className="media-left">
                                                        <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                            ? dbuser.l1_tb
                                                            : "0"} ({dbuser !== null
                                                                ? dbuser.l1_count
                                                                : "0"})</span></h3>
                                                        <p className="game-content fs-6">Level 1 (Team)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 my-5">
                                            <div className="game-card style2">
                                                <div className="game-card-img">
                                                    <div className="game-logo"><img src="assets/img/next_rank.svg" alt="YBL" /></div>
                                                </div>
                                                <div className="game-card-details">
                                                    <div className="media-left">
                                                        <h3 className="box-title fs-4"><span className="text-theme">${dbuser !== null
                                                            ? dbuser.l2_tb
                                                            : "0"} ({dbuser !== null
                                                                ? dbuser.l2_count
                                                                : "0"})</span></h3>
                                                        <p className="game-content fs-6">Level 2 (Team)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </section >
                    <div className="container">
                        <div className='row d-flex align-items-center justify-content-center'>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="" className='text-start text-white'>Enter Level</label>
                                    <input className="form-control" type="text" placeholder="Enter Level" value={level}
                                        onChange={(e) => setlevel(Number(e.target.value))} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="form-group">
                                    <label htmlFor="" className='text-start text-white'>Enter User Name</label>
                                    <input className="form-control" type="text" placeholder="Enter value" value={selectedPvalue}
                                        onChange={(e) => setselectedPvalue(e.target.value)} />
                                </div>
                            </div>

                            <div className="col-lg-2">
                                <label htmlFor="" className='text-start text-white'>From</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <label htmlFor="" className='text-start text-white'>To</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={todate} onChange={(e) => settodate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button>
                            </div>
                        </div>

                        <div className="table-responsive">
                            <table className="table tournament-table">
                                <thead>
                                    <tr>

                                        {/* <th scope="col">#</th>
                                        <th scope="col">Hash</th>
                                        <th scope="col">From</th>
                                        <th scope="col">Reward</th>
                                        <th scope="col">Reward(%)</th>
                                        <th scope="col">Level</th>
                                        <th scope="col">Investment Details</th>
                                        <th scope="col">Direct</th>
                                        <th scope="col">Team Business</th>
                                        <th scope="col">Date</th> */}

                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Display Name</th>
                                        <th scope="col">Investment Amount</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Reward(%)</th>
                                        <th scope="col">Reward</th>
                                        {/* <th scope="col">Team Business</th> */}
                                        <th scope="col">Level</th>
                                        {/* <th scope="col">Direct</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={10}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {ele.id}
                                                </td>
                                                <td>
                                                    {ele.datetime}
                                                </td>
                                                <td >
                                                    <span
                                                        onClick={() => copyaddress(ele.from_uname)}
                                                    >
                                                        {ele.from_uname}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span
                                                        // onClick={() => copyaddress(ele.from)}
                                                        onClick={() => copyaddress(ele.from_dname)}
                                                    >
                                                        {/* {formatAddress(ele.from)}{" "} */}
                                                        {ele.from_dname}
                                                    </span>
                                                </td>
                                                <td>
                                                    $ {ele.staking}
                                                </td>
                                                <td>
                                                    $ {ele.s_inc}
                                                </td>
                                                <td>
                                                    {ele.income_per} %
                                                </td>
                                                <td>
                                                    $ {ele.income}
                                                </td>
                                                {/* <td>
                                                    $ {ele.team_business}
                                                </td> */}
                                                <td>
                                                    {ele.level}
                                                </td>
                                                {/* <td>
                                                    {ele.direct}
                                                </td> */}

                                            </tr>
                                        );
                                    })}
                                </tbody>
                                <thead>
                                    <tr>

                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col">Total</th>
                                        <th scope="col">$ {sumIncome ?Number(sumIncome.staking).toFixed(2):0}</th>
                                        <th scope="col">$ {sumIncome ?Number(sumIncome.s_inc).toFixed(2):0}</th>
                                        <th scope="col"></th>
                                        <th scope="col">$ {sumIncome ?Number(sumIncome.sum).toFixed(2):0}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
