import React, { useContext, useEffect, useRef, useState } from 'react';
import DashboardSidebar from '../Comman/DashboardSidebar';
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Ticket() {
    const { showmenu, setshowmenu, account, toastSuccess } = useContext(ProjectContext);
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(null);
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [selectedPvalue, setselectedPvalue] = useState(null);
    const [level, setlevel] = useState(0);

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);

    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);

    const { ticketid } = useParams();
    const intervalRef = useRef(null);


    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxSize = 2 * 1024 * 1024; // 2MB

        if (!allowedTypes.includes(file.type)) {
            setError('Only PNG, JPEG, and JPG files are allowed.');
            return false;
        }

        if (file.size > maxSize) {
            setError('File size should be less than 2MB.');
            return false;
        }

        setError('');
        return true;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && validateFile(file)) {
            setSelectedFile(file);
            convertToBase64(file);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && validateFile(file)) {
            setSelectedFile(file);
            convertToBase64(file);
        }
    };

    const convertToBase64 = (file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            console.log("Base64 String of the image:", base64String);
            // Set preview URL for displaying the image
            setPreviewUrl(base64String);
        };
        reader.readAsDataURL(file);
    };

    const getChatData = async () => {
        try {
            setisLoading(true);
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getchat",
                key: process.env.REACT_APP_KEY,
                ticketid: ticketid
            });

            const resData = response.data;

            if (resData.success) {
                const newMessages = resData.data.map(chat => ({
                    id: chat.id,
                    message: chat.message,
                    message_image: chat.message_image,
                    type: chat.type,
                    date: new Date(chat.date),
                }));

                setChatMessages(newMessages);
                // setMessage('');

            } else {
                console.error("Error:", resData.error);
            }
        } catch (err) {
            console.error("Error: ", err);
        } finally {
            setisLoading(false);
        }
    };

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getticketbyid",
                ticketid: ticketid,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                if (res.data.error) {
                    setdata([]);
                    setsumIncome(null);
                    return;
                }
                console.log("get data", res.data.data[0]);
                setdata(res.data.data[0]);

            });

    };

    // // Polling mechanism using setInterval
    // useEffect(() => {
    //     // Fetch chat data immediately on component mount
    //     getChatData();

    //     // Set up polling to fetch chat messages every 5 seconds
    //     intervalRef.current = setInterval(getChatData, 5000);

    //     // Cleanup interval on component unmount
    //     return () => clearInterval(intervalRef.current);
    // }, [ticketid]);

    const sendMessage = async () => {
        try {
            const formData = new FormData();
            formData.append('method', 'user');
            formData.append('submethod', 'inserchat');
            formData.append('key', process.env.REACT_APP_KEY);
            formData.append('message', message);

            if (selectedFile) {
                formData.append('file', selectedFile); // Append the file directly
            }
            formData.append('type', 'user');
            formData.append('ticketid', ticketid);
            formData.append('status', true);

            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            // if (response.data.success) {
            //     getData();
            //     getChatData();
            // }
            if (response.data.success) {
                // Clear input fields and reset file input
                setMessage('');
                setSelectedFile(null);
                setPreviewUrl(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }

                // Fetch updated chat messages
                getChatData();
            }
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };


    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const CloseTicket = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "user",
                    submethod: "closeticket",
                    key: process.env.REACT_APP_KEY,
                    ticketid: ticketid,
                    ticket_status: false
                })
                .then((res) => {
                    // console.log("res.data", res.data);
                    if (res.data.error) {
                        console.log("res.data.error", res.data.error);
                    }
                    toastSuccess("Ticket Close");
                });
        } catch (err) {
            console.log("error : ", err);
        }
    };

    useEffect(() => {
        getChatData();

        setTimeout(() => {
            getData();
            getChatData();
        }, 1000);
    }, [account, pageSize, currentPage]);
    return (
        <>
            <DashboardSidebar />

            <div className='home-section'>
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                        <div className="tournament-sec-bg-shape" />
                        <div className="container">
                            <div className="row gy-4 filter-active justify-content-center">
                                <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                                    <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                        <h2 className="sec-title">Ticket</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row gy-4 filter-active justify-content-center align-items-end mb-5">
                                <div className="col-lg-5">
                                    <h5 className="sec-title mb-0">Ticket Title : {data.ticket_title}</h5>
                                    <p><span className='text-white'>Ticket Description :</span> {data.ticket_description}</p>

                                    {/* {
                                        data.filter(item => item.status === false).length ? (
                                            ""
                                        ) : ( */}
                                    <button className="th-btn" onClick={CloseTicket}>Close Ticket</button>
                                    {/* )
                                    } */}

                                </div>
                                <div className='col-5'>
                                    <img src={`https://yblwin.ai/starter/tickets/${data.ticket_image}`} alt="Not Found" width={150} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="chat-container">
                        <div className="chat-header">
                            <h2>Support Room</h2>
                        </div>

                        <div className="chat-messages">
                            {chatMessages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`chat-message ${message.type === "user" ? "sent" : "received"}`}
                                >
                                    <div className="message-info">
                                        <span className="sender fw-bold">{message.type === "user" ? "You" : "Admin"} &nbsp;</span>
                                        <span className="timestamp">
                                            {new Date(message.date).toLocaleDateString()} at {new Date(message.date).toLocaleTimeString()}
                                        </span>
                                    </div>

                                    {message.message_image ?
                                        <div className="message-text">
                                            <img src={`${process.env.REACT_APP_CHAT_IMAGE}${message.message_image}`} alt="Not Found" width={150} />
                                        </div>
                                        :
                                        ""
                                    }
                                    <div className="message-text">
                                        {message.message ? message.message : " "}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {error && <p className='text-center' style={{ color: 'red' }}>{error}</p>}

                        <div className="chat-input-container">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className="chat-input"
                                placeholder="Type your message..."
                            />
                            <div
                                onDragOver={handleDragOver}
                                onDrop={handleDrop}
                                onClick={handleClick}
                                style={{ position: 'relative', zIndex: '99', padding: '0px 5px', textAlign: 'center', cursor: 'pointer', fontSize: '20px' }}
                            >
                                {previewUrl ? (
                                    <img src={previewUrl} alt="Preview" style={{ maxWidth: '90px', height: 'auto' }} />
                                ) : (
                                    <i className='fa fa-paperclip' />
                                )}
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none', }}
                                    onChange={handleFileChange}
                                />
                            </div>

                            <button onClick={sendMessage} className="chat-send-btn">
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
