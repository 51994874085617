import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';

export default function WithdrawalHistory() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [datap, setdatap] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(0);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                console.log("vvsdf", res.data);
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    const getpData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "withdrawal",
                submethod: "getpbyid",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                // var dedata = decryptData(res.data);
                console.log("dasbdjahsjdhajsd", res.data);
                if (res.data.error) {
                    return "";
                }
                setdatap(res.data.data);
            });
    };

    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);
    useEffect(() => {
        getData();
        getpData()
    }, [account]);
    return (
        <>
            <DashboardSidebar />

            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    {datap !== null ?
                        <div className="container">
                            <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                <h2 className="sec-title">Pending Withdrawal </h2>
                            </div>
                            <div className="table-responsive">
                                <table className="table tournament-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Admin Fee</th>
                                            <th>Gaming point</th>
                                            <th>Received Amount</th>

                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {datap.map((ele, i) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {i + 1}
                                                    </td>
                                                    <td > {ele.datetime}
                                                    </td>
                                                    <td >
                                                        $ {ele.amount}
                                                    </td>

                                                    <td >
                                                        {ele.fee} USDT (5%)
                                                    </td>
                                                    <td >
                                                        {ele.fee_gaming} USDT (10%)
                                                    </td>
                                                    {/* <td >
                                                    <span className='text-theme' > Admin:</span> {ele.fee} USDT (5%) <br />
                                                    <span className='text-theme'>Gaming:</span> {ele.fee_gaming} USDT (10%)
                                                </td> */}
                                                    <td >
                                                        {ele.t_rcv} USDT
                                                    </td>
                                                    {/* <td >
                                                        $ {ele.rate}
                                                    </td> */}
                                                    <td className='text-warning'> Pending
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div> : ''}
                    <div className="container pt-4">
                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <h2 className="sec-title">Withdrawal History </h2>
                        </div>
                        <div className="table-responsive">
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Hash</th>
                                        <th>Amount</th>
                                        <th>Admin Fee</th>
                                        <th>Gaming point</th>
                                        <th>Received Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={8}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}

                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {i + 1}
                                                </td>
                                                <td > {ele.datetime}
                                                </td>
                                                <td >
                                                    <span
                                                        onClick={() => copyaddress(ele.hash)}
                                                    >
                                                        <a href={`${process.env.REACT_APP_EXPLORER}tx/${ele.hash}`} target='_blank'>
                                                            {formatAddress(ele.hash)}{" "}
                                                        </a>
                                                        <i className="fa fa-copy"></i>
                                                    </span>
                                                </td>
                                                <td >
                                                    $ {ele.amount}
                                                </td>
                                                <td >
                                                    {ele.fee} USDT (5%)
                                                </td>
                                                <td >
                                                    {ele.fee_gaming} USDT (10%)
                                                </td>
                                                {/* <td >
                                                    <span className='text-theme' > Admin:</span> {ele.fee} USDT (5%) <br />
                                                    <span className='text-theme'>Gaming:</span> {ele.fee_gaming} USDT (10%)
                                                </td> */}
                                                <td >
                                                    {ele.t_rcv} USDT
                                                </td>
                                                {/* <td >
                                                    $ {ele.rate}
                                                </td> */}

                                                <td className='text-success'> Success
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
