import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';
import Pagination from '../Comman/Pagination';
import { Link } from 'react-router-dom';

export default function Support() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress, dbuser } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);
    const [sumIncome, setsumIncome] = useState(null);
    const [fromdate, setfromdate] = useState(null);
    const [todate, settodate] = useState(null);
    const [selectedPvalue, setselectedPvalue] = useState(null);
    const [level, setlevel] = useState(0);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getticketbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    setdata([]);
                    setsumIncome(null)
                    return "";
                }
                console.log("ticket_id", res.data.data)
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
                const sum = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.income;
                }, 0);
                const sumstaking = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.staking;
                }, 0);
                const sums_inc = res.data.data.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue.s_inc;
                }, 0);
                setsumIncome({
                    sum: sum,
                    staking: sumstaking,
                    s_inc: sums_inc
                })

            });
    };
    useEffect(() => {

        getData();
    }, [account, pageSize, currentPage]);

    return (
        <>
            <DashboardSidebar />

            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <section className="tournament-sec-5 overflow-hidden" data-overlay="title">
                        <div className="tournament-sec-bg-shape" />
                        <div className="container">
                            <div className="row gy-4 filter-active justify-content-center">
                                <div className="col-lg-12 col-md-12 filter-item tour-cat1 tour-all">
                                    <div className='d-flex justify-content-between'>
                                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                                            <h2 className="sec-title">Community Bonus </h2>
                                        </div>
                                        <div className="col-lg-3">
                                            {/* {data ? data.filter(item => item.status === true).length : 0} */}
                                            {/* <Link to='/add-ticket' className="th-btn" disabled={data.filter(item => item.status === true).length || "Wait Close"}>Add Ticket</Link> */}
                                            {
                                                data.filter(item => item.status === true).length ? (
                                                    <button className="th-btn" disabled>
                                                        Wait To Close
                                                    </button>
                                                ) : (
                                                    <Link to='/add-ticket' className="th-btn">
                                                        Add Ticket
                                                    </Link>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="row gy-30 filter-active justify-content-center">
                                        <div className="col-lg-3 col-md-6 my-5">
                                            <div className="game-card style2">
                                                <div className="game-card-img">
                                                    <div className="game-logo"><img src="assets/img/total_business_month.svg" alt="YBL" /></div>
                                                </div>
                                                <div className="game-card-details">
                                                    <div className="media-left">
                                                        <h3 className="box-title fs-4"><span className="text-theme">{data ? data.length : 0}</span></h3>
                                                        <p className="game-content fs-6">Totall Ticket</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 my-5">
                                            <div className="game-card style2">
                                                <div className="game-card-img">
                                                    <div className="game-logo"><img src="assets/img/next_rank.svg" alt="YBL" /></div>
                                                </div>
                                                <div className="game-card-details">
                                                    <div className="media-left">
                                                        <h3 className="box-title fs-4"><span className="text-theme">{data ? data.filter(item => item.status === true).length : 0}</span></h3>
                                                        <p className="game-content fs-6">Pending Ticket</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6 my-5">
                                            <div className="game-card style2">
                                                <div className="game-card-img">
                                                    <div className="game-logo"><img src="assets/img/leg.svg" alt="YBL" /></div>
                                                </div>
                                                <div className="game-card-details">
                                                    <div className="media-left">
                                                        <h3 className="box-title fs-4"><span className="text-theme">{data ? data.filter(item => item.status === false).length : 0}</span></h3>
                                                        <p className="game-content fs-6">Resolve Ticket</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </section >
                    <div className="container">
                        {/* <div className='row d-flex align-items-center justify-content-center'>
                            <div className="col-lg-3">
                                <div className="form-group">
                                    <label htmlFor="" className='text-start text-white'>Enter User Name</label>
                                    <input className="form-control" type="text" placeholder="Enter value" value={selectedPvalue}
                                        onChange={(e) => setselectedPvalue(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="" className='text-start text-white'>From</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <label htmlFor="" className='text-start text-white'>To</label>
                                <div className="form-group">
                                    <input className="form-control" type="date" placeholder="Enter level" value={todate} onChange={(e) => settodate(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <button onClick={() => getData()} className="th-btn">Search NOW <i className="far fa-arrow-right ms-2" /></button>
                            </div>
                        </div> */}
                        <div className="table-responsive">
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Discription</th>
                                        <th scope="col">Images</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={9}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {ele.id}
                                                </td>
                                                <td>
                                                    {ele.createdAt}
                                                </td>
                                                <td>
                                                    $ {ele.ticket_title}
                                                </td>
                                                <td style={{ width: "350px" }}>
                                                    <span className="text-wrap">
                                                        {ele.ticket_description}
                                                    </span>
                                                </td>
                                                <td>
                                                    <img src={`https://yblwin.ai/starter/tickets/${ele.ticket_image}`} alt="Not Found" width={150} />
                                                </td>
                                                <td>
                                                    {ele.status}
                                                </td>
                                                <td>
                                                    <span className="me-3">
                                                        <Link
                                                            className="edit-appointment"
                                                            type="button"
                                                            to={`/ticket/${ele.ticket_id}`}
                                                        >
                                                            <i
                                                                className="fa fa-book fs-18 "
                                                                aria-hidden="true"
                                                            />
                                                        </Link>
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Pagination
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                                pages={pages}
                            />
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}
