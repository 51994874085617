import React, { useContext, useEffect, useRef, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function AddTicket() {
    const {
        account,
        toastSuccess,
        toastError,
        getuserDB,
    } = useContext(ProjectContext);
    const [ttitle, setTtitle] = useState("");
    const [tdesc, setDesc] = useState("");
    const [trdone, settrdone] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [error, setError] = useState('');
    const fileInputRef = useRef(null);
    const navigate = useNavigate();

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const validateFile = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const maxSize = 2 * 1024 * 1024; // 2MB

        if (!allowedTypes.includes(file.type)) {
            setError('Only PNG, JPEG, and JPG files are allowed.');
            return false;
        }

        if (file.size > maxSize) {
            setError('File size should be less than 2MB.');
            return false;
        }

        setError('');
        return true;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        console.log("event.dataTransfer.files[0]", event.dataTransfer.files[0]);
        
        const file = event.dataTransfer.files[0];
        if (file && validateFile(file)) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && validateFile(file)) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const ticket = async () => {
        try {
            const formData = new FormData();
            formData.append('method', 'user');
            formData.append('submethod', 'addticket');
            formData.append('key', process.env.REACT_APP_KEY);
            formData.append('address', account);
            formData.append('ticket_title', ttitle);
            formData.append('ticket_description', tdesc);
    
    
            if (selectedFile) {
                formData.append('file', selectedFile);
            }
    
            // Log FormData content for debugging
            for (let [key, value] of formData.entries()) {
                if (key === 'ticket_image') {
                    console.log("images name-->",`${key}: ${value.name}`); // Log the image file name
                } else {
                    console.log(`${key}: ${value}`);
                }
            }
    
            const response = await axios.post(process.env.REACT_APP_BACKEND_LINK, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data.error) {
                toastError(response.data.error);
            } else {
                toastSuccess(response.data.data);
                navigate("/support")
            }
        } catch (error) {
            console.error("Error making API call:", error?.response || error);
            toastError("Failed to submit ticket. Please check the console for more details.");
        }
    
        getuserDB();
        settrdone(false);
    };
    

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                <br /><br />
                <div className="contact-sec-1 bg-repeat overflow-hidden space" style={{ backgroundImage: `url("assets/img/bg/jiji-bg2.png")` }}>
                    <div className="container-fluid p-0">
                        <div className="contact-wrap-1">
                            <div className="row justify-content-around">
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left">
                                        <span className="sub-title style2"># Create Ticket</span>
                                        <h2 className="sec-title text-white">Create Ticket</h2>
                                    </div>
                                    <div className="row">
                                        <div className="form-group style-border2 col-md-12">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Ticket Title"
                                                value={ttitle}
                                                onChange={(e) => setTtitle(e.target.value)}
                                            />
                                            <i className="fal fa-usd" />
                                        </div>
                                        <div className="col-12 form-group style-border2">
                                            <textarea
                                                placeholder="Ticket Description...."
                                                value={tdesc}
                                                onChange={(e) => setDesc(e.target.value)}
                                                className="form-control"
                                            />
                                            <i className="far fa-pencil" />
                                        </div>
                                        <div className="form-btn col-12 my-5">
                                            <button className="th-btn" onClick={ticket}>
                                                Submit <i className="fa-solid fa-arrow-right ms-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-5 col-xl-6">
                                    <div className="title-area custom-anim-left">
                                        <h2 className="sec-title text-white">Upload Images</h2>
                                    </div>
                                    <div
                                        onDragOver={handleDragOver}
                                        onDrop={handleDrop}
                                        onClick={handleClick}
                                        style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center', cursor: 'pointer' }}
                                    >
                                        {previewUrl ? (
                                            <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                                        ) : (
                                            <p>Drag & drop an image here, or click to select a file</p>
                                        )}
                                    </div>

                                    {error && <p style={{ color: 'red' }}>{error}</p>}

                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

