import React, { useContext, useEffect, useState } from 'react'
import DashboardSidebar from '../Comman/DashboardSidebar'
import { ProjectContext } from '../../ProjectContext';
import axios from 'axios';

export default function StakingList() {
    const { showmenu, setshowmenu, account, copyaddress, formatAddress } = useContext(ProjectContext)
    const [data, setdata] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [pages, setPages] = useState(1);

    const getData = async () => {
        if (!account) {
            return;
        }
        setisLoading(true);

        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "transaction",
                submethod: "getbyid",
                // address: "0x501c21a99aaad6a36bd811884c2a1ca2cacdf21f",
                address: account,
                key: process.env.REACT_APP_KEY,
                page: currentPage,
                pageSize: pageSize
            })
            .then((res) => {
                setisLoading(false);
                // var dedata = decryptData(res.data);
                if (res.data.error) {
                    return "";
                }
                setdata(res.data.data);
                setPages(Math.ceil(res.data.dataLength / pageSize));
            });
    };
    useEffect(() => {
        getData();
    }, [account, pages, pageSize, currentPage]);

    return (
        <>
            <DashboardSidebar />
            <div className='home-section'>
                {/* <DashboardHeader />s */}
                <div className="point-table-area-1 space overflow-hidden" data-bg-src="assets/img/bg/tournament-table-sec1-bg.png">
                    <div className="container">
                        <div className="title-area text-center custom-anim-top wow animated" data-wow-duration="1.5s" data-wow-delay="0.2s">
                            <h2 className="sec-title">Investment History </h2>
                        </div>
                        <div className="table-responsive">
                            <table className="table tournament-table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        {/* <th scope="col">Username</th> */}
                                        <th scope="col">Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Hash</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="text-center">
                                        {isLoading ? "Data is loading" : ""}
                                    </tr>
                                    {!isLoading ? (
                                        !data.length ? (
                                            <tr className="text-center">
                                                <td className="text-center" colSpan={7}>
                                                    <span className="w-100">No data found</span>
                                                </td>
                                            </tr>
                                        ) : (
                                            ""
                                        )
                                    ) : (
                                        ""
                                    )}
                                    {data.map((ele, i) => {
                                        return (
                                            <tr>
                                                <td >
                                                    {ele.id}
                                                </td>

                                                <td >
                                                    {ele.createdAt}
                                                </td>
                                                <td >
                                                    $ {ele.amount}
                                                </td>
                                                <td >
                                                    <span
                                                        onClick={() => copyaddress(ele.hash)}
                                                    >
                                                        <a href={`${process.env.REACT_APP_EXPLORER}tx/${ele.hash}`} target='_blank'>
                                                            {formatAddress(ele.hash)}{" "}
                                                        </a>
                                                        <i className="fa fa-copy"></i>
                                                    </span>
                                                </td>

                                                <td >
                                                    {ele.status ? "Over" : "Running"}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
