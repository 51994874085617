import React, { createContext, useEffect, useState } from "react";
import App from "./App";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import copy from "copy-to-clipboard";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useSwitchNetwork,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useWeb3Modal } from "@web3modal/ethers5/react";
import { ethers } from "ethers";

export const ProjectContext = createContext();
// const projectId = "431f8884a97bfb789c09e7092c0c5353";
const projectId = process.env.REACT_APP_PROJECT_ID;
// const mainnet = [{
//   chainId: 80002,
//   name: "Amoy Testnet",
//   currency: "MATIC",
//   explorerUrl: "https://www.oklink.com/amoy",
//   rpcUrl: "https://rpc-amoy.polygon.technology",
// }];
const mainnet = [{
  chainId: 56,
  name: "BSC Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed.binance.org",
}];


const metadata = {
  name: "YBLWIN",
  description: "YBLWIN AI Gaming",
  url: process.env.REACT_APP_LINK, // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata,
    defaultChainId: mainnet[0].chainId,
    enableEIP6963: true,
    enableInjected: true,
    enableCoinbase: true,
    rpcUrl: "...", // used for the Coinbase SDK
  }),
  chains: mainnet,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Projectcontext() {
  var { address, chainId, isConnected } = useWeb3ModalAccount();
  // address = "0x2464694ac90f695c7472ca6354ea1beb3791cfaf";
  const { disconnect } = useDisconnect()
  const { switchNetwork } = useSwitchNetwork()
  // const { error } = useWeb3ModalError()

  const [maticbalance, setmaticBalance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [settings, setsettings] = useState([]);
  const [Ranks, setRanks] = useState([]);
  const [rate, setrate] = useState(0);
  const [esigner, setesigner] = useState(null);
  const [dashD, setdashD] = useState(null);
  const [tokenrate, settokenrate] = useState(1);
  const [dbbalance, setdbbalance] = useState(0);
  const [dbuser, setdbuser] = useState(null);
  const [account, setaccount] = useState(address);
  const [dbuserload, setdbuserload] = useState(false);
  const [stakcontract, setstakcontract] = useState(null);
  const [reloadData, setreloadData] = useState(false);
  const { open } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();

  const [isClassToggled, setIsClassToggled] = useState(false);
  const [showmenu, setshowmenu] = useState(false);

  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };
  function reload() {
    setreloadData(!reloadData)
  }
  const connectInjected = async () => {
    try {
      await open();
    } catch (ex) {
      console.log(ex);
    }
  };
  const toastError = (data) => {
    toast.error(data);
  };
  const toastSuccess = (data) => {
    toast.success(data);
  };
  const toastInfo = (data) => {
    toast(data, {
      // Custom Icon
      icon: "❕",
      // Aria
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const copyaddress = (address) => {
    copy(address);
    toastSuccess("Copied");
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "rank",
        submethod: "rank",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setRanks(res.data.data);
      });
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "dashd",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdashD(res.data.data);
      });
  };

  async function disconnectNow() {
    try {
      // deactivate();
      disconnect()
      localStorage.setItem("isconnected", false);
      setdbuser(null)
      setdbuserload(false)
      setBalance(0);
    } catch (ex) {
      console.log(ex);
    }
  }

  const connectMetamask = async () => {
    try {
      open();

      localStorage.setItem("isconnected", true);
    } catch (error) {
      alert(error);
    }
  };

  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };
  function formatDateTime(timestamp) {
    if (timestamp === 0) {
      return '-'
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds

    // Get the date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Get the time components
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Construct the formatted date-time string
    const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  }
  const getuserDB = async () => {
    if (!account) {
      return
    }
    setdbuserload(false)
    var data = await getudata(account);
    if (data.data.error) {
      setdbuser(null);
      setdbuserload(true)
      return "";
    }
    setdbuser(data.data.data);
    var dap = data.data.data;
    var fbln =
      dap.level_inc +
      dap.rank_inc +
      dap.staking_inc +
      dap.team_royalty_inc +
      dap.community_inc +
      dap.admin_staking_inc +
      dap.roiofroi_inc -
      (dap.t_withdrawal + dap.pen_withdrawal);
    setdbbalance(Number(fbln).toFixed(2));

    const timer = setTimeout(() => {
      setdbuserload(true)
    }, 1500); // 3000 milliseconds = 3 seconds

    // Cleanup the timeout if the component unmounts
    return () => clearTimeout(timer);

  };

  useEffect(() => {
    getuserDB();
    // }, [account]);
  }, [account, reloadData]);
  useEffect(() => {
    // if (localStorage.getItem("isconnected") === "true") {
    //   connectMetamask();
    // }
    getSetting();
  }, []);

  // useEffect(() => {
  //   if (chainId !== 80001) {
  //     console.log("error", error);
  //     addchain();
  //   }
  // }, [chainId]);
  const getSigner = async () => {
    if (!isConnected) return;

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    setesigner(signer)
    const balance = await ethersProvider.getBalance(account);
    setmaticBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

  }
  const getsmcs = async () => {
    if (!isConnected || !settings.token || esigner === null) return;
    let contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    let maincontractwrite = new ethers.Contract(
      settings.contract,
      JSON.parse(settings.contract_abi),
      esigner
    );
    setstakcontract(maincontractwrite)

    var balance = await contract.balanceOf(account);
    setBalance(Number(ethers.utils.formatUnits(balance, 18)).toFixed(2));

    // var tknrate = await maincontractwrite.tokenrate();
    // settokenrate(Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2));

  };
  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, account, walletProvider, settings, esigner, reloadData]);
  useEffect(() => {
    getSigner();
  }, [isConnected, account, walletProvider]);
  function checkNegative(value) {
    if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }
  useEffect(() => {
    setaccount(address);
    // if (address) {
    //   localStorage.setItem("isconnected", true);
    // }
  }, [address]);
  // useEffect(() => {
  //   console.log("accountaccount", account);
  // }, [account])

  return (
    <>
      <Toaster
        position="top-left"
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#ffa518",
            color: "#fff",
          },
          success: {
            duration: 3000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <ProjectContext.Provider
        value={{
          settings: settings,
          library: ethers,
          balance,
          maticbalance,
          dbuser, dashD,
          getuserDB,
          esigner, Ranks,
          tokenrate,
          account: account ? account.toLowerCase() : account,
          connectInjected,
          disconnectNow, checkNegative,
          connectMetamask,
          formatAddress,
          toastError,
          toastSuccess,
          getudata, dbbalance, dbuserload,
          toastInfo,
          getsmcs,
          ethers,
          rate,
          copyaddress,
          stakcontract,
          formatDateTime, setaccount,
          reload, reloadData, switchNetwork,
          isClassToggled, setIsClassToggled, showmenu, setshowmenu
        }}
      >
        <App />
      </ProjectContext.Provider>
    </>
  );
}

export default Projectcontext;
